<template>
  <div>
    <b-modal :title=" $t('dashboard.store.addressesModal.title') " content-class="card card-ecart form-rounded-inputs"  header-class="card-header p-3 "
      centered v-model="modal.address" @hidden="resetAddress()" cancel-variant="danger" size="md" hide-header-close>

      <v-observer tag="form" class="animated fadeIn" ref="formStoreAddress" @submit.prevent="fnValidateAddress()">

        <div class="row">

          <div class="col-12">
            <div class="form-group">
              <label for="" class=" w-100 " v-text=" $t('dashboard.store.addressesModal.storeName') ">
              </label>
              <v-validation rules="required|min:3|max:30" v-slot="{ errors }"
                :name=" $t('dashboard.store.addressesModal.storeName') ">
                <input type="text" v-model="storeAddress.address_name"
                  :placeholder="$t('dashboard.store.addressesModal.storeName') " class="form-control"
                  :class=" { 'is-invalid':errors.length > 0 } ">
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </v-validation>
            </div>
          </div>

          <div class="col-6 col-sm-6">
            <div class="form-group">
              <label for="" v-text=" $t('general.form.firstName') "> </label>
              <v-validation rules="required|min:3|max:30" v-slot="{ errors }" :name=" $t('general.form.firstName') ">
                <input type="text" v-model="storeAddress.first_name" placeholder="Robert" class="form-control"
                  :class=" { 'is-invalid':errors.length > 0 } ">
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </v-validation>
            </div>
          </div>

          <div class="col-6 col-sm-6">
            <div class="form-group">
              <label for="" v-text=" $t('general.form.lastName') "> </label>
              <v-validation rules="required|min:3|max:30" v-slot="{ errors }" :name=" $t('general.form.lastName') ">
                <input type="text" v-model="storeAddress.last_name" class="form-control" placeholder="Martin"
                  :class=" { 'is-invalid':errors.length > 0 } ">
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </v-validation>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="form-group">
              <label for="" v-text=" $t('general.form.address1') "> </label>
              <v-validation rules="required|min:3|max:30" v-slot="{ errors }" :name=" $t('general.form.address1') ">
                <input type="text" v-model="storeAddress.address1" class="form-control" placeholder="Street name "
                  :class=" { 'is-invalid':errors.length > 0 } ">
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </v-validation>
            </div>
          </div>

          <div class="col-12 col-md-6">
            <div class="form-group">
              <label for="" v-text=" $t('general.form.address2') "> </label>
              <v-validation rules="required|max:30" v-slot="{ errors }" :name=" $t('general.form.address2') ">
                <input type="text" v-model="storeAddress.address2" class="form-control" placeholder="Street number"
                  :class=" { 'is-invalid':errors.length > 0 } ">
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </v-validation>
            </div>
          </div>

          <div class="col-12 col-sm-6">
            <v-validation rules="required" v-slot="{ errors }" :name=" $t('general.form.country') ">
              <div class="form-group">
                <label for="" v-text="$t('general.form.country')"></label>
                <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                  <select class="custom-select" @change="fnGetStates( storeAddress.country.code , true )"
                    :class=" { 'is-invalid ':errors.length > 0 } " v-model="storeAddress.country.code">
                    <option :value="null" selected v-text=" $t('general.form.select') ">
                    </option>
                    <option :value="country.code" v-for=" country in countriesList  " :key="country.name"
                      v-text="country.name">
                    </option>
                  </select>
                  <div class="input-group-append">
                    <label class="input-group-text"> <i class="fa "
                        :class=" loading.countries ? 'fa-spinner fa-spin':'fa-flag' "></i> </label>
                  </div>
                </div>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </div>
            </v-validation>
          </div>

          <div class="col-12 col-sm-6">
            <div v-show=" statlesList.length == 0 ">
              <v-validation :rules=" statlesList.length == 0 ? 'required' : ''" v-slot="{ errors }"
                :name=" $t('general.form.state') ">
                <div class="form-group">
                  <label for="">
                    <span v-text=" $t('general.form.state') "></span>
                  </label>
                  <div class="input-group">
                    <input type="text" class="form-control" v-model="storeAddress.state.name" placeholder="Nuevo Leon"
                      :class=" { 'is-invalid':(errors.length > 0 || storeAddress.state.name == null ) } ">
                    <div class="input-group-append">
                      <span class="input-group-text rounded-right">
                        <i class="fa" :class=" loading.states ? 'fa-spinner fa-spin':'fa-map-marker-alt' "></i>
                      </span>
                    </div>
                    <div class="invalid-feedback" v-if="errors.length > 0 ">
                      {{ errors[0] }}
                    </div>
                  </div>
                </div>
              </v-validation>
            </div>

            <div v-show=" statlesList.length > 0 ">
              <v-validation :rules=" statlesList.length > 0 ? 'required' : ''" v-slot="{ errors }"
                :name="  $t('general.form.state') ">
                <div class="form-group">
                  <label for="" v-text="$t('general.form.state')"></label>
                  <div class="input-group " :class=" { 'is-invalid ':errors.length > 0 } ">
                    <select class="custom-select" @change="fnGetProvinces(storeAddress.state.code,true)"
                      :class=" { 'is-invalid ':errors.length > 0 } " v-model="storeAddress.state.code">
                      <option :value="null" selected v-text=" $t('general.form.select') ">
                      </option>
                      <option :value="std['code_2_digits']" v-for=" (std,index) in  statlesList  " :key="index"
                        v-text="std.name">
                      </option>
                    </select>
                    <div class="input-group-append">
                      <label class="input-group-text"> <i class="fa "
                          :class=" loading.states ? 'fa-spinner fa-spin':'fa-map-marker-alt' "></i> </label>
                    </div>
                  </div>
                  <div class="invalid-feedback" v-if="errors.length > 0">
                    {{ errors[0] }}
                  </div>
                </div>
              </v-validation>
            </div>
          </div>

          <div class="col-12 col-md-4 ">
            <div class="form-group" v-show=" provincesList.length == 0 ">
              <label for="" v-text="$t('general.form.city')"> </label>
              <v-validation :rules=" provincesList.length == 0 ? 'required|min:3|max:30' : ''" v-slot="{ errors }"
                :name=" $t('general.form.city') ">
                <input type="text" v-model="storeAddress.city" class="form-control" placeholder="Monterrey"
                  :class=" { 'is-invalid':errors.length > 0 } ">
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </v-validation>
            </div>
            <div class="form-group" v-show=" provincesList.length > 0 ">
              <label for="" v-text=" $t('general.form.province')  "> </label>
              <v-validation :rules=" provincesList.length > 0 ? 'required' : ''" v-slot="{ errors }"
                :name=" $t('general.form.province') ">
                <select class="form-control" v-model="storeAddress.city" :class=" { 'is-invalid':errors.length > 0 } ">
                  <option :value="null" selected v-text=" $t('general.form.select') "> </option>
                  <option class="text-capitalize" :value="prov.code" v-for=" (prov,index) in provincesList  "
                    :key="index + 'prov'" v-text="prov.name">
                  </option>
                </select>
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </v-validation>
            </div>
          </div>

          <div class="col-6 col-sm-6 col-md-4">
            <div class="form-group">
              <label for=""
                v-text=" (storeAddress.country.code != 'CO') ?  $t('general.form.zipCode') : $t('general.form.nitCode') ">
              </label>
              <v-validation rules="required|min:3|max:30" v-slot="{ errors }" :name=" $t('general.form.zipCode') ">
                <input type="text" v-model="storeAddress.postal_code" placeholder="67614" class="form-control"
                  :class=" { 'is-invalid':errors.length > 0 } ">
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </v-validation>
            </div>
          </div>

          <div class="col-6 col-sm-6 col-md-4">
            <div class="form-group">
              <label for="" v-text=" $t('general.form.phone') "> </label>
              <v-validation rules="required|min:10" v-slot="{ errors }" :name=" $t('general.form.phone')">
                <the-mask :mask="['##-####-####']" placeholder="81-8000-8000" v-model="storeAddress.phone "
                  class="form-control" :class=" { 'is-invalid':errors.length > 0 } " />
                <div class="invalid-feedback" v-if="errors.length > 0">
                  {{ errors[0] }}
                </div>
              </v-validation>
            </div>
          </div>

        </div>
        <input type="submit" hidden>
      </v-observer>

      <template v-slot:modal-footer>
        <div class="d-flex jusify-content-end ">
          <b-button variant="none" class="mr-2" @click=" changeModal({address:false}); resetAddress()"
            v-text=" $t('general.button.close') ">
          </b-button>
          <b-button variant="success" class=" " @click=" fnValidateAddress() " :disabled="loading.address">
            <span
              v-text=" (storeAddress.id !=null) ? $t('general.button.update') : ( integration ? $t('general.button.next') : $t('general.button.add') ) "
              v-if=" !loading.address "></span>
            <i class="fa fa-spinner fa-spin " v-else></i>
          </b-button>
        </div>
      </template>

    </b-modal>
  </div>
</template>

<script>
  import {
    mapActions,
    mapState,
    mapMutations
  } from 'vuex'
  export default {
    data() {
      return {
        provincesList: [],
        statlesList: [],
        countries: [],
      }
    },
    computed: {
      ...mapState('EcartStore', ['storeInformation', 'addressesList', 'storeAddress', 'integration', 'loading', 'modal',
        'updateAddressId'
      ]),
      ...mapState('session', ['user']),
    },
    watch: {
      modal() {
        this.fnUpdateAddress();
      }
    },
    methods: {
      ...mapMutations('EcartStore', ['changeLoading', 'resetAddress', 'setAddressInfo', 'changeModal']),
      ...mapActions('EcartStore', ['fnApiAddStoreAddress']),

      fnUpdateAddress() {
        if (this.updateAddressId) {
          let address = {
            ...this.addressesList.find(address => address.id == this.updateAddressId)
          };
          this.setAddressInfo({
            address,
            userInfo: this.user,
          });
          if (address.state.code) {
            this.fnGetProvinces(address.state.code);
          }
          this.fnGetStates(this.storeAddress.country.code);
        } else {
          this.fnPreloadAddress();
        }

      },

      async fnValidateAddress() {
        if (await this.$refs['formStoreAddress'].validate()) {
          this.fnApiAddStoreAddress((this.statlesList.length > 1) ? true : false);
        } else {
          this.fnGlobalScrollToError(this.$refs['formStoreAddress'].refs, true);
        }
      },

      async fnGetStates(code, countryChanged) {
        this.loading.states = true;
        this.statlesList = [];
        this.statlesList = await this.fnApiGetStates(code);
        if (countryChanged) {
          this.storeAddress.state.code = null;
          this.storeAddress.state.name = null;
          if (this.statlesList.length > 0) {
            this.storeAddress.state.code = this.statlesList[0].code_2_digits;
            this.fnGetProvinces(this.storeAddress.state.code, true);
          }
        }
        this.loading.states = false;
      },

      async fnGetProvinces(code, stateChanged) {
        if (this.storeAddress.country.code == 'CO' && this.storeAddress.state.code) {
          this.provincesList = await this.fnApiGetProvinces(code);
          if (stateChanged) {
            this.storeAddress.city = null;
            if (this.provincesList.length > 0) {
              this.storeAddress.city = this.provincesList[0].code;
            }
          }
        } else {
          this.provincesList = [];
          if (stateChanged) {
            this.storeAddress.city = null;
          }
        }
      },

      fnPreloadAddress() {
        this.storeAddress.phone = this.user.phone || null;
        this.storeAddress.first_name = this.user.first_name || null;
        this.storeAddress.last_name = this.user.last_name || null;
        this.storeAddress.country.code = this.user.country || null;
        this.fnGetStates(this.storeAddress.country.code, true);
      }

    },

    async mounted() {
      this.changeLoading({
        countries: true
      });
      this.countriesList = await this.fnApiGetCountries();
      this.changeLoading({
        countries: false
      });
    }
  }
</script>